.c-hero {

	.block-tam {
		position: relative;

		@include respond-until(md) {
			background-color: $maroon;
		}

		@include respond-to(sm) {}

		img {
			width: 90px;

			@include respond-until(md) {
				margin: 0 auto;
				display: block;
				padding-top: 6px;
				padding-bottom: 6px;
				width: 48px;
			}

			@include respond-from(md) {
				position: absolute;
				top: 20px;
				z-index: 999;
				padding-left: 20px;
			}
		}
	}

	.masthead {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;
			width: 100%;
			height: 300px;
			@include respond-from(md) {
			// height: 100vh; /* if you don't want it to take up the full screen, reduce this number */
			height: 75vh; /* if you don't want it to take up the full screen, reduce this number */

			}
			overflow: hidden;
			background-size: cover;
			background-image: url(/assets/img/midnight-yell-hero.jpg);
			background-repeat: no-repeat;
			background-position: center center;

		h1 {

			color: #eee;
			font-size: 32vmin;
			letter-spacing: 0.03em;
			line-height: 1;
			text-shadow: 10px 12px 23px rgba(0, 0, 0, 0.3);
			// margin-bottom: 26px;
			text-transform: uppercase;
			font-family: "Tungsten Comp A", "Tungsten Comp B";
			font-style: normal;
			font-weight: 800;
			width: 100vw;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		svg {
			filter: drop-shadow(0 0 0.65rem #500000);
			width: 80%;
			@include respond-from(md) {
				width: 55%;
			}
		}

	}
}

.cls-3 {
	@include respond-until(md) {
		stroke-width: 7px !important;
	 }
}

.down-arrow {
	// -moz-animation: bounce 2.5s infinite;
	// -webkit-animation: bounce 2.5s infinite;
	// animation: bounce 2.5s infinite;
	width: 25px;
	@include respond-to(sm) {
		display: none;
		
	}
	a {
		color: $white;
		&:hover {
			color: #c9c9c9;
		}
	}
	svg {
		
		@include respond-until(md) {
			// margin: 0 auto;
			// display: block;
		}
		
	}
	
	@include respond-from(md) {
	position: absolute;
	bottom: 50px;
	height: 0;
	z-index: 999;
	margin: 0 50%;
	width: 25px;

	
}
}
