body {
	font-family: "Open Sans";
	font-size: 16px;
}


main {
  //max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.hide {
  display: none;
}

div.main {
  @include respond-from(md) {
		  margin-left: 0;
	  margin-right: 0;
	  @include respond-until(xl) {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	  flex-direction: column;
	  //width: 600px
	  aside {
		// width: 100%;
		width: 25%;
		ul {
		  border-right: none;
		  li {
			text-align: center;
		  }
		}
	  }
	  article {
		width: 100%;
	  }
	}

  article {
	width: 100%;
	@include respond-from(md) {
	width: 75%;
	}
    section {
			padding: 1.5rem;
			margin-bottom: 1.5rem;
      background-color: #500000;
      color: white;
	  	height: 100%;
	  h2 {
			font-weight: bold;
			font-size: 2rem;
			font-style: italic;
			}
			li {
	    list-style-type: disc;
	    margin-left: 20px;
	    text-indent: 10px;
	    line-height: 1.5rem;
			}
			
    }

  }
}


p {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 1.5rem;
	a {
		color: $blue-100;
		text-decoration: none;
		border-bottom: 1px dotted;
		&:hover {
			color: $blue-200;
			text-decoration: none;
			border-bottom: 1px solid $blue-200;
		}
	}
}


.c-three-columns {
	background-color: #500000;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 2rem 0;
	// max-width: 1600px;
	@include respond-to(sm) {
	//flex-wrap: wrap;
	// flex-direction: column;
	}
	color: white;
	section {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	//   flex-wrap: wrap;
	  font-size: 1rem;
	//   font-style: italic;
	  text-align: center;
	  padding: 2rem 0;
	  line-height: 1.375;
	  
	  max-width: 350px;
	  @include respond-to(md) {
		max-width: 250px;
	  }
	  text-transform: uppercase;
	  svg {
		height: 80px;
		margin-bottom: 1.25rem;
		fill: #ffffff;
		width: 100%;
		&:hover {
			// fill: #abb7b7;
		}
	  }
	  a {
		  color: #fff;
		  font-weight: 600;
		  
		  box-sizing: border-box;
		  padding-bottom: 4px;
		  text-decoration: none;
		  letter-spacing: 1px;
		  line-height: 1.5;
		  span {
			border-bottom: 1px dotted #fff;
		  }
		  &:hover {
			color: #c9c9c9;
			span {
				color: #c9c9c9;
			border-bottom: 1px solid #c9c9c9;
			}
			svg {
				fill: #c9c9c9;
			}
		  }
		  
		  
	  }
	//   div {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	//   }
// 	  &:nth-child(2) {
// 		display: flex;
// 		flex-direction: row;
// 		&::before {
// 		  content: "";
// 	  display: block;
// 	  margin: 0 auto;
// 	  height: 50%;
// 	  // padding-left: 20px;
// 	  border-left: 2px solid #c9c9c9;
// 		}
// 		&::after {
// 		 content: "";
// 	  display: block;
// 	  margin: 0 auto;
// 	  height: 50%;
// 	  // padding-left: 20px;
// 	  border-right: 2px solid #c9c9c9;
// 		}
// 	  // border-left: 2px solid white;
// 		// border-right: 2px solid white;
//   }
 }
}

.c-virtual {
	background-color: #fff;
	// margin-top: 5rem;
	padding: 2rem;
	section {
		text-align: center;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	h3 {
		font-weight: bold;
		font-size: 2rem;
		font-style: italic;
		line-height: 2.5rem;
		color: $maroon;
	}
	p {
		margin-bottom: 0;
	}
	@include respond-to(sm) {
		br {
			display: none;
		}
		}
}


.c-video {
	background-color: $gray-200;
	// margin-top: 5rem;
	padding: 2rem;
	@include respond-until(md) {
    margin-top: 0;
  }
	section {
		text-align: center;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	h3 {
		font-weight: bold;
		font-size: 2rem;
		font-style: italic;
		line-height: 2.5rem;
		color: $maroon;
	}
	@include respond-to(sm) {
		br {
			display: none;
		}
		}
}



.c-follow {
	display: flex;
	justify-content: center;
	color: #500000;
	font-size: 1.5rem;
	padding: 2rem 0;
	@include respond-from(md) {
	font-size: 2rem;
	padding: 4rem 0;
	}
	font-weight: 600;
	font-style: italic;
	
	@include respond-to(sm) {
		flex-direction: column;
		text-align: center;
		line-height: 3rem;
	}
	div {
	  margin: 0 1rem;
	  span {
		margin: 0 0.75rem;
	  }
	a {
	  color: #500000;
	  &:hover {
		color: #c9c9c9;
	  }
	}
  }
  }

  .c-button {
	display: inline-block;
	margin: 0;
	padding-top: 1rem;
	padding-right: 1.875rem;
	padding-bottom: 1rem;
	padding-left: 1.875rem;
	transition: 0.3s ease-in-out;
	border-width: 3px;
	border-style: solid;
	font-family: $open-sans;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
  }
  .c-button_white-ghost {
		background-color: transparent;
		border-color: $white;
		color: $white;
  }
  .c-button_white-ghost:hover {
		background-color: $white;
		color: $maroon;
  }
  
  .c-button_youtube {
		  background-color: #c4302b !important;
		  border-color: #c4302b !important;
		  color: $white !important;
  }
  .c-button_youtube:hover {
		  background-color: $white !important;
		  color: #c4302b !important;
  }