.c-questions section {
    display:none;
}

.c-questions section {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
  color: #ffffff;
}