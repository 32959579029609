
.c-tabs {
  margin-top: 2rem;
margin-bottom: 2rem;
  @include respond-from(md) {
margin-top: 4rem;
margin-bottom: 4rem;
  }
}



.accordion-title {
    border: 0;
    margin: 0;
    font-size: 14px;
    color: #500000;
    background-color: #e3e3e3;
    text-decoration: none;
    padding: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    // @include respond-from(md) {
    border-bottom: 2px solid #ffffff;
    // }
    letter-spacing: 1px;
    @include respond-until(md) {
      text-align: center;
    }
}
li.is-active {
  .accordion-title{
    // @include respond-to(md) {
    border-bottom: 2px solid #E4002B !important;
    // }
  }
}

.accordion-content {
  background-color: #500000;
  color: #ffffff;
  border: 0;
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    font-style: italic;
    margin-top: 0.5rem;
    }
    li {
    list-style-type: disc;
    margin-left: 20px;
    line-height: 1.5rem;
    }
  p {
    line-height: 1.5;
    font-weight: 600;
  }
  img {
    max-width: 100%;
  }
  a {
    color: #fff;
    border-bottom: 1px solid #fff;
    &:hover, &:focus {
      color: #939393;
      border-bottom: 3px solid #939393;
    }
  }
}

#info-tabs {
  @include respond-from(md) {
  border-right: 4px solid #E4002B;
  }
}
.tabs {
  border: 0;
}
.tabs-title {
    font-size: 14px;
    color: #500000;
    background-color: #e3e3e3;
    text-decoration: none;
    text-transform: uppercase;
    //font-weight: 600;
    border-bottom: 2px solid #ffffff;
    letter-spacing: 1px;
    &:focus {
      outline: none;
    }

    a {
      color: #500000;
      text-decoration: none;


      &:hover {
        color: #ffffff;
      background-color: #333333;
      }
      &:focus {
        outline: none;
        color: #ffffff;
      background-color: #3e3e3e;
      }

      }
      a[aria-selected="true"] {
        color: #ffffff;
        background-color: #500000;
      }

      .is-active {
        background-color: #500000 !important;
        color: #ffffff !important;
      a {
        color: #ffffff;
        background-color: #500000;
        &:hover, &:focus {
          color: #ffffff;
          background-color: #500000;
        }
       }
    }
}
.tabs-title.is-active a {
  color: #ffffff;
  background-color: #500000;
}
.tabs-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
			margin-bottom: 1.5rem;
      background: #500000 !important;
      color: #ffffff !important;
      height: 100%;
      border: 0;
	  h2 {
			font-weight: 700;
			font-size: 2rem;
      font-style: italic;
      margin-top: 0.5rem;
			}
			li {
	    list-style-type: disc;
	    margin-left: 20px;
	    text-indent: 10px;
	    line-height: 1.5rem;
			}
    p {
      line-height: 1.5;
      font-weight: 600;
    }
    img {
      max-width: 100%;
    }
    a {
      color: #fff;
      border-bottom: 1px solid #fff;
      &:hover, &:focus {
        color: #939393;
        border-bottom: 3px solid #939393;
      }
    }
}
