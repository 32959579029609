@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://cloud.typography.com/6280314/6684992/css/fonts.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Oswald:400,600,700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * @section Base Styles
 */
/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.display-block {
  display: block !important; }

.display-table {
  display: table !important; }

.display-table-cell {
  display: table-cell !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.border-box {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important; }

.border-none {
  border: none !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-0 {
  padding: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-1 {
  padding: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-2 {
  padding: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-3 {
  padding: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.clearfix::after {
  clear: both; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media print, screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.99875em) {
  .show-for-medium {
    display: none !important; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important; } }

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important; } }

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important; } }

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important; } }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #2ba6cb transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  right: 1rem;
  left: auto; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion[disabled] .accordion-title {
  cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 3px 3px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 3px 3px; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #2ba6cb; }

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 3px 3px; }

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6; }

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+"; }

.is-active > .accordion-title::before {
  content: "–"; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.tabs::after {
  clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }

.tabs.simple > li > a:hover {
  background: transparent; }

.tabs.primary {
  background: #2ba6cb; }

.tabs.primary > li > a {
  color: #0a0a0a; }

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #299ec1; }

.tabs-title {
  float: left; }

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1; }

[data-whatinput='mouse'] .tabs-title > a {
  outline: 0; }

.tabs-title > a:hover {
  background: #fefefe; }

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #e6e6e6; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }

.tabs-panel.is-active {
  display: block; }

.row {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.row .row {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem; }

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

.row .row.collapse {
  margin-right: 0;
  margin-left: 0; }

.row.expanded {
  max-width: none; }

.row.expanded .row {
  margin-right: auto;
  margin-left: auto; }

.row:not(.expanded) .row {
  max-width: none; }

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0; }

.column, .columns {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0px;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  min-width: 0; }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-1 > .column, .small-up-1 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.small-up-2 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-2 > .column, .small-up-2 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.small-up-3 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-3 > .column, .small-up-3 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-up-4 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-4 > .column, .small-up-4 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.small-up-5 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-5 > .column, .small-up-5 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.small-up-6 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-6 > .column, .small-up-6 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-up-7 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-7 > .column, .small-up-7 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 14.28571%;
  -ms-flex: 0 0 14.28571%;
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.small-up-8 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-up-8 > .column, .small-up-8 > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12.5%;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .medium-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 14.28571%;
    -ms-flex: 0 0 14.28571%;
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .medium-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%; }

@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-1 > .column, .large-up-1 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .large-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-2 > .column, .large-up-2 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .large-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-3 > .column, .large-up-3 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-4 > .column, .large-up-4 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .large-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-5 > .column, .large-up-5 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .large-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-6 > .column, .large-up-6 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-7 > .column, .large-up-7 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 14.28571%;
    -ms-flex: 0 0 14.28571%;
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .large-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .large-up-8 > .column, .large-up-8 > .columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%; }

@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.875rem; }

.column-block > :last-child {
  margin-bottom: 0; }

.align-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.align-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.align-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-justify {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.align-spaced {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.align-left.vertical.menu > li > a {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.align-right.vertical.menu > li > a {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.align-self-top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.align-bottom {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.align-self-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.align-middle {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.align-self-middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.align-self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }

.align-center-middle {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center; }

.small-order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.small-order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.small-order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.small-order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4; }

.small-order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5; }

.small-order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .medium-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .medium-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .medium-order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .medium-order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .medium-order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .large-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .large-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .large-order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .large-order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .large-order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; } }

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flex-child-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.flex-child-grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; }

.flex-child-shrink {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.flex-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-dir-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.flex-dir-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-dir-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .medium-flex-child-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .large-flex-child-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .large-flex-child-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .large-flex-dir-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.slide-in-left.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0); }

.slide-in-up.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.slide-in-right.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0); }

.slide-out-down.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.fade-in.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(90deg);
  transform: perspective(2000px) rotateX(90deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(90deg);
  transform: perspective(2000px) rotateY(90deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(90deg);
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(90deg);
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(90deg);
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(90deg);
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(-0.75turn);
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  -webkit-transform: rotate(0.75turn);
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0.75turn);
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  -webkit-transform: rotate(-0.75turn);
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  -webkit-transition-duration: 750ms !important;
  transition-duration: 750ms !important; }

.fast {
  -webkit-transition-duration: 250ms !important;
  transition-duration: 250ms !important; }

.linear {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important; }

.ease {
  -webkit-transition-timing-function: ease !important;
  transition-timing-function: ease !important; }

.ease-in {
  -webkit-transition-timing-function: ease-in !important;
  transition-timing-function: ease-in !important; }

.ease-out {
  -webkit-transition-timing-function: ease-out !important;
  transition-timing-function: ease-out !important; }

.ease-in-out {
  -webkit-transition-timing-function: ease-in-out !important;
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  -webkit-transition-delay: 300ms !important;
  transition-delay: 300ms !important; }

.long-delay {
  -webkit-transition-delay: 700ms !important;
  transition-delay: 700ms !important; }

.shake {
  -webkit-animation-name: shake-7;
  animation-name: shake-7; }

@-webkit-keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%); } }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%); } }

.spin-cw {
  -webkit-animation-name: spin-cw-1turn;
  animation-name: spin-cw-1turn; }

@-webkit-keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn); }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

@keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn); }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

.spin-ccw {
  -webkit-animation-name: spin-ccw-1turn;
  animation-name: spin-ccw-1turn; }

@-webkit-keyframes spin-ccw-1turn {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn); } }

@keyframes spin-ccw-1turn {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn); } }

.wiggle {
  -webkit-animation-name: wiggle-7deg;
  animation-name: wiggle-7deg; }

@-webkit-keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms; }

.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.slow {
  -webkit-animation-duration: 750ms !important;
  animation-duration: 750ms !important; }

.fast {
  -webkit-animation-duration: 250ms !important;
  animation-duration: 250ms !important; }

.linear {
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important; }

.ease {
  -webkit-animation-timing-function: ease !important;
  animation-timing-function: ease !important; }

.ease-in {
  -webkit-animation-timing-function: ease-in !important;
  animation-timing-function: ease-in !important; }

.ease-out {
  -webkit-animation-timing-function: ease-out !important;
  animation-timing-function: ease-out !important; }

.ease-in-out {
  -webkit-animation-timing-function: ease-in-out !important;
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  -webkit-animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  -webkit-animation-delay: 300ms !important;
  animation-delay: 300ms !important; }

.long-delay {
  -webkit-animation-delay: 700ms !important;
  animation-delay: 700ms !important; }

/* -- Open Sans -- */
/* -- Color Variables -- */
/* --Background Colors -- */
.u-bg-maroon {
  background-color: #500000 !important; }

.u-bg-maroon-100 {
  background-color: #732f2f !important; }

.u-bg-maroon-200 {
  background-color: #500000 !important; }

.u-bg-maroon-300 {
  background-color: #3C0000 !important; }

.u-bg-red {
  background-color: #e4002b !important; }

.u-bg-yellow {
  background-color: #fce300 !important; }

.u-bg-green {
  background-color: #3ed488 !important; }

.u-bg-gray-100 {
  background-color: #f6f8f7 !important; }

.u-bg-gray-200 {
  background-color: #eef0f0 !important; }

.u-bg-gray-300 {
  background-color: #d1d5d4 !important; }

.u-bg-gray-400 {
  background-color: #a7abaa !important; }

.u-bg-gray-500 {
  background-color: #707373 !important; }

.u-bg-gray-600 {
  background-color: #3e3e3e !important; }

.u-bg-gray-700 {
  background-color: #332c2c !important; }

.u-bg-gray-800 {
  background-color: #2b2021 !important; }

.u-bg-gray-900 {
  background-color: #241618 !important; }

.u-bg-black {
  background-color: #000000 !important; }

.u-bg-blue-100 {
  background-color: #006483 !important; }

.u-bg-blue-200 {
  background-color: #004362 !important; }

.u-bg-tan-100 {
  background-color: #f5efe6 !important; }

.u-bg-tan-200 {
  background-color: #e7ded0 !important; }

.u-bg-tan-300 {
  background-color: #d6d3c4 !important; }

/* -- Font Weights -- */
.u-font-weight-300 {
  font-weight: 300 !important; }

.u-font-weight-400 {
  font-weight: 400 !important; }

.u-font-weight-600 {
  font-weight: 600 !important; }

.u-font-weight-700 {
  font-weight: 700 !important; }

.u-font-weight-800 {
  font-weight: 800 !important; }

/* --  Font Weights, Tungsten -- */
.u-tungsten {
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 400;
  letter-spacing: 2px !important; }

.u-tungsten-400 {
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 400;
  letter-spacing: 2px !important; }

.u-tungsten-600 {
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 600;
  letter-spacing: 2px !important; }

.u-tungsten-700 {
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 700;
  letter-spacing: 2px !important; }

/* -- Font Weights, Tungsten Narrow -- */
.u-tungsten-narrow {
  font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
  font-weight: 400;
  letter-spacing: 2px !important; }

.u-tungsten-narrow-400 {
  font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
  font-weight: 400;
  letter-spacing: 2px !important; }

.u-tungsten-narrow-600 {
  font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
  font-weight: 600;
  letter-spacing: 2px !important; }

.u-tungsten-narrow-700 {
  font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
  font-weight: 700;
  letter-spacing: 2px !important; }

/* -- Font Weights, Open Sans -- */
.u-open-sans {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important; }

.u-open-sans-300 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300 !important; }

.u-open-sans-400 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important; }

.u-open-sans-600 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important; }

.u-open-sans-700 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important; }

.u-open-sans-800 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800 !important; }

/* -- Base Font Sizes -- */
/* -- Font Sizes, s-xl -- */
.u-font-size-xs {
  font-size: .85em !important; }

.u-font-size-sm {
  font-size: 1em !important; }

.u-font-size-md {
  font-size: 1.25em !important; }

.u-font-size-lg {
  font-size: 1.5em !important; }

.u-font-size-xl {
  font-size: 1.75em !important; }

/* -- Font Colors for white or light backgrounds, check contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-maroon {
  color: #500000 !important; }

.u-color-gray-500 {
  color: #707373 !important; }

.u-color-gray-600 {
  color: #3e3e3e !important; }

.u-color-gray-700 {
  color: #332c2c !important; }

.u-color-gray-800 {
  color: #2b2021 !important; }

.u-color-gray-900 {
  color: #241618 !important; }

.u-color-black {
  color: #000000 !important; }

.u-color-blue-100 {
  color: #006483 !important; }

.u-color-blue-200 {
  color: #004362 !important; }

/* -- Font Colors for dark backgrounds, check color contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-white {
  color: #ffffff; }

.u-color-gray-100 {
  color: #f6f8f7 !important; }

.u-color-gray-200 {
  color: #eef0f0 !important; }

.u-color-gray-200 {
  color: #d1d5d4 !important; }

/* -- Text Align -- */
.u-text-align-l {
  text-align: left !important; }

.u-text-align-c {
  text-align: center !important; }

.u-text-align-r {
  text-align: right !important; }

.u-text-align-i {
  text-align: inherit !important; }

/* -- Text Transform -- */
.u-text-transform-u {
  text-transform: uppercase !important; }

.u-text-transform-l {
  text-transform: uppercase !important; }

/* -- Letter Spacing -- */
.u-letter-spacing-1 {
  letter-spacing: 1px !important; }

.u-letter-spacing-2 {
  letter-spacing: 2px !important; }

.u-letter-spacing-3 {
  letter-spacing: 3px !important; }

/* -- Borders -- */
.u-border {
  border: 1px solid !important; }

.u-border-t {
  border-top: 1px solid !important; }

.u-border-r {
  border-right: 1px solid !important; }

.u-border-b {
  border-bottom: 1px solid !important; }

.u-border-l {
  border-left: 1px solid !important; }

/* -- Padding -- */
.u-padding-sm {
  padding: 1rem !important; }

.u-padding-md {
  padding: 1.25rem !important; }

.u-padding-lg {
  padding: 1.5rem !important; }

.u-padding-xl {
  padding: 2rem !important; }

.u-padding-t-sm {
  padding-top: 1rem !important; }

.u-padding-t-md {
  padding-top: 1.25rem !important; }

.u-padding-t-lg {
  padding-top: 1.5rem !important; }

.u-padding-t-xl {
  padding-top: 2rem !important; }

.u-padding-r-sm {
  padding-right: 1rem !important; }

.u-padding-r-md {
  padding-right: 1.25rem !important; }

.u-padding-r-lg {
  padding-right: 1.5rem !important; }

.u-padding-r-xl {
  padding-right: 2rem !important; }

.u-padding-b-sm {
  padding-bottom: 1rem !important; }

.u-padding-b-md {
  padding-bottom: 1.25rem !important; }

.u-padding-b-lg {
  padding-bottom: 1.5rem !important; }

.u-padding-b-xl {
  padding-bottom: 2rem !important; }

.u-padding-l-sm {
  padding-left: 1rem !important; }

.u-padding-l-md {
  padding-left: 1.25rem !important; }

.u-padding-l-lg {
  padding-left: 1.5rem !important; }

.u-padding-l-xl {
  padding-left: 2rem !important; }

/* -- Margins -- */
.u-margin-center {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-margin-sm {
  margin: 1rem !important; }

.u-margin-md {
  margin: 1.25rem !important; }

.u-margin-lg {
  margin: 1.5rem !important; }

.u-margin-xl {
  margin: 2rem !important; }

.u-margin-t-sm {
  margin-top: 1rem !important; }

.u-margin-t-md {
  margin-top: 1.25rem !important; }

.u-margin-t-lg {
  margin-top: 1.5rem !important; }

.u-margin-t-xl {
  margin-top: 2rem !important; }

.u-margin-r-sm {
  margin-right: 1rem !important; }

.u-margin-r-md {
  margin-right: 1.25rem !important; }

.u-margin-r-lg {
  margin-right: 1.5rem !important; }

.u-margin-r-xl {
  margin-right: 2rem !important; }

.u-margin-b-sm {
  margin-bottom: 1rem !important; }

.u-margin-b-md {
  margin-bottom: 1.25rem !important; }

.u-margin-b-lg {
  margin-bottom: 1.5rem !important; }

.u-margin-b-xl {
  margin-bottom: 2rem !important; }

.u-margin-l-sm {
  margin-left: 1rem !important; }

.u-margin-l-md {
  margin-left: 1.25rem !important; }

.u-margin-l-lg {
  margin-left: 1.5rem !important; }

.u-margin-l-xl {
  margin-left: 2rem !important; }

/* -- Vertical Alignments -- */
.u-vertical-align-t {
  vertical-align: top !important; }

.u-vertical-align-m {
  vertical-align: middle !important; }

.u-vertical-align-b {
  vertical-align: bottom !important; }

.u-vertical-align-text-t {
  vertical-align: text-top !important; }

.u-vertical-align-text-b {
  vertical-align: text-bottom !important; }

/* -- Widths -- */
.u-width-10 {
  width: 10% !important; }

.u-width-20 {
  width: 20% !important; }

.u-width-30 {
  width: 30% !important; }

.u-width-40 {
  width: 40% !important; }

.u-width-50 {
  width: 50% !important; }

.u-width-60 {
  width: 60% !important; }

.u-width-70 {
  width: 70% !important; }

.u-width-80 {
  width: 80% !important; }

.u-width-90 {
  width: 90% !important; }

.u-width-100 {
  width: 100% !important; }

.show-for-sr {
  display: none; }

body {
  font-family: "Open Sans";
  font-size: 16px; }

main {
  margin-right: auto;
  margin-left: auto; }

.hide {
  display: none; }

@media (min-width: 768px) {
  div.main {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column; } }
  @media (min-width: 768px) and (max-width: 1280px) {
    div.main {
      margin-left: 1rem;
      margin-right: 1rem; } }

@media (min-width: 768px) {
    div.main aside {
      width: 25%; }
      div.main aside ul {
        border-right: none; }
        div.main aside ul li {
          text-align: center; }
    div.main article {
      width: 100%; } }

div.main article {
  width: 100%; }
  @media (min-width: 768px) {
    div.main article {
      width: 75%; } }
  div.main article section {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #500000;
    color: white;
    height: 100%; }
    div.main article section h2 {
      font-weight: bold;
      font-size: 2rem;
      font-style: italic; }
    div.main article section li {
      list-style-type: disc;
      margin-left: 20px;
      text-indent: 10px;
      line-height: 1.5rem; }

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem; }
  p a {
    color: #006483;
    text-decoration: none;
    border-bottom: 1px dotted; }
    p a:hover {
      color: #004362;
      text-decoration: none;
      border-bottom: 1px solid #004362; }

.c-three-columns {
  background-color: #500000;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 0;
  color: white; }
  .c-three-columns section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    padding: 2rem 0;
    line-height: 1.375;
    max-width: 350px;
    text-transform: uppercase; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .c-three-columns section {
        max-width: 250px; } }
    .c-three-columns section svg {
      height: 80px;
      margin-bottom: 1.25rem;
      fill: #ffffff;
      width: 100%; }
    .c-three-columns section a {
      color: #fff;
      font-weight: 600;
      box-sizing: border-box;
      padding-bottom: 4px;
      text-decoration: none;
      letter-spacing: 1px;
      line-height: 1.5; }
      .c-three-columns section a span {
        border-bottom: 1px dotted #fff; }
      .c-three-columns section a:hover {
        color: #c9c9c9; }
        .c-three-columns section a:hover span {
          color: #c9c9c9;
          border-bottom: 1px solid #c9c9c9; }
        .c-three-columns section a:hover svg {
          fill: #c9c9c9; }

.c-virtual {
  background-color: #fff;
  padding: 2rem; }
  .c-virtual section {
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .c-virtual h3 {
    font-weight: bold;
    font-size: 2rem;
    font-style: italic;
    line-height: 2.5rem;
    color: #500000; }
  .c-virtual p {
    margin-bottom: 0; }
  @media (max-width: 640px) {
    .c-virtual br {
      display: none; } }

.c-video {
  background-color: #eef0f0;
  padding: 2rem; }
  @media (max-width: 768px) {
    .c-video {
      margin-top: 0; } }
  .c-video section {
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .c-video h3 {
    font-weight: bold;
    font-size: 2rem;
    font-style: italic;
    line-height: 2.5rem;
    color: #500000; }
  @media (max-width: 640px) {
    .c-video br {
      display: none; } }

.c-follow {
  display: flex;
  justify-content: center;
  color: #500000;
  font-size: 1.5rem;
  padding: 2rem 0;
  font-weight: 600;
  font-style: italic; }
  @media (min-width: 768px) {
    .c-follow {
      font-size: 2rem;
      padding: 4rem 0; } }
  @media (max-width: 640px) {
    .c-follow {
      flex-direction: column;
      text-align: center;
      line-height: 3rem; } }
  .c-follow div {
    margin: 0 1rem; }
    .c-follow div span {
      margin: 0 0.75rem; }
    .c-follow div a {
      color: #500000; }
      .c-follow div a:hover {
        color: #c9c9c9; }

.c-button {
  display: inline-block;
  margin: 0;
  padding-top: 1rem;
  padding-right: 1.875rem;
  padding-bottom: 1rem;
  padding-left: 1.875rem;
  transition: 0.3s ease-in-out;
  border-width: 3px;
  border-style: solid;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer; }

.c-button_white-ghost {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff; }

.c-button_white-ghost:hover {
  background-color: #ffffff;
  color: #500000; }

.c-button_youtube {
  background-color: #c4302b !important;
  border-color: #c4302b !important;
  color: #ffffff !important; }

.c-button_youtube:hover {
  background-color: #ffffff !important;
  color: #c4302b !important; }

.c-hero .block-tam {
  position: relative; }
  @media (max-width: 768px) {
    .c-hero .block-tam {
      background-color: #500000; } }
  .c-hero .block-tam img {
    width: 90px; }
    @media (max-width: 768px) {
      .c-hero .block-tam img {
        margin: 0 auto;
        display: block;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 48px; } }
    @media (min-width: 768px) {
      .c-hero .block-tam img {
        position: absolute;
        top: 20px;
        z-index: 999;
        padding-left: 20px; } }

.c-hero .masthead {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-size: cover;
  background-image: url(/assets/img/midnight-yell-hero.jpg);
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 768px) {
    .c-hero .masthead {
      height: 75vh;
      /* if you don't want it to take up the full screen, reduce this number */ } }
  .c-hero .masthead h1 {
    color: #eee;
    font-size: 32vmin;
    letter-spacing: 0.03em;
    line-height: 1;
    text-shadow: 10px 12px 23px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    font-family: "Tungsten Comp A", "Tungsten Comp B";
    font-style: normal;
    font-weight: 800;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; }
  .c-hero .masthead svg {
    filter: drop-shadow(0 0 0.65rem #500000);
    width: 80%; }
    @media (min-width: 768px) {
      .c-hero .masthead svg {
        width: 55%; } }

@media (max-width: 768px) {
  .cls-3 {
    stroke-width: 7px !important; } }

.down-arrow {
  width: 25px; }
  @media (max-width: 640px) {
    .down-arrow {
      display: none; } }
  .down-arrow a {
    color: #ffffff; }
    .down-arrow a:hover {
      color: #c9c9c9; }
  @media (min-width: 768px) {
    .down-arrow {
      position: absolute;
      bottom: 50px;
      height: 0;
      z-index: 999;
      margin: 0 50%;
      width: 25px; } }

.c-questions section {
  display: none; }

.c-questions section {
  color: #ffffff; }

.c-tabs {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .c-tabs {
      margin-top: 4rem;
      margin-bottom: 4rem; } }

.accordion-title {
  border: 0;
  margin: 0;
  font-size: 14px;
  color: #500000;
  background-color: #e3e3e3;
  text-decoration: none;
  padding: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 2px solid #ffffff;
  letter-spacing: 1px; }
  @media (max-width: 768px) {
    .accordion-title {
      text-align: center; } }

li.is-active .accordion-title {
  border-bottom: 2px solid #E4002B !important; }

.accordion-content {
  background-color: #500000;
  color: #ffffff;
  border: 0; }
  .accordion-content h2 {
    font-weight: 700;
    font-size: 1.5rem;
    font-style: italic;
    margin-top: 0.5rem; }
  .accordion-content li {
    list-style-type: disc;
    margin-left: 20px;
    line-height: 1.5rem; }
  .accordion-content p {
    line-height: 1.5;
    font-weight: 600; }
  .accordion-content img {
    max-width: 100%; }
  .accordion-content a {
    color: #fff;
    border-bottom: 1px solid #fff; }
    .accordion-content a:hover, .accordion-content a:focus {
      color: #939393;
      border-bottom: 3px solid #939393; }

@media (min-width: 768px) {
  #info-tabs {
    border-right: 4px solid #E4002B; } }

.tabs {
  border: 0; }

.tabs-title {
  font-size: 14px;
  color: #500000;
  background-color: #e3e3e3;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid #ffffff;
  letter-spacing: 1px; }
  .tabs-title:focus {
    outline: none; }
  .tabs-title a {
    color: #500000;
    text-decoration: none; }
    .tabs-title a:hover {
      color: #ffffff;
      background-color: #333333; }
    .tabs-title a:focus {
      outline: none;
      color: #ffffff;
      background-color: #3e3e3e; }
  .tabs-title a[aria-selected="true"] {
    color: #ffffff;
    background-color: #500000; }
  .tabs-title .is-active {
    background-color: #500000 !important;
    color: #ffffff !important; }
    .tabs-title .is-active a {
      color: #ffffff;
      background-color: #500000; }
      .tabs-title .is-active a:hover, .tabs-title .is-active a:focus {
        color: #ffffff;
        background-color: #500000; }

.tabs-title.is-active a {
  color: #ffffff;
  background-color: #500000; }

.tabs-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 1.5rem;
  background: #500000 !important;
  color: #ffffff !important;
  height: 100%;
  border: 0; }
  .tabs-content h2 {
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;
    margin-top: 0.5rem; }
  .tabs-content li {
    list-style-type: disc;
    margin-left: 20px;
    text-indent: 10px;
    line-height: 1.5rem; }
  .tabs-content p {
    line-height: 1.5;
    font-weight: 600; }
  .tabs-content img {
    max-width: 100%; }
  .tabs-content a {
    color: #fff;
    border-bottom: 1px solid #fff; }
    .tabs-content a:hover, .tabs-content a:focus {
      color: #939393;
      border-bottom: 3px solid #939393; }

.c-footer {
  background: #f6f8f7;
  padding-top: 2rem; }
  .c-footer section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 70rem;
    margin: auto; }
    .c-footer section img {
      width: 14rem;
      height: auto; }
    .c-footer section .c-footer_social-items {
      display: flex;
      max-width: 425px;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 2rem auto 2rem; }
      @media (min-width: 768px) {
        .c-footer section .c-footer_social-items:after, .c-footer section .c-footer_social-items:before {
          content: "";
          border-top: 1px solid;
          -webkit-box-flex: 1;
          flex: 1 0 40px;
          color: #b3b3b3; } }
      .c-footer section .c-footer_social-items:before {
        margin: 0 1rem 0 0; }
      .c-footer section .c-footer_social-items:after {
        margin: 0 0 0 1rem; }
      .c-footer section .c-footer_social-items ul {
        display: flex;
        justify-content: space-evenly; }
        .c-footer section .c-footer_social-items ul li {
          list-style: none;
          margin: 0 0.375rem; }
          .c-footer section .c-footer_social-items ul li a {
            color: #500000; }
            .c-footer section .c-footer_social-items ul li a:hover {
              color: #c9c9c9; }
    .c-footer section .c-footer_contact {
      margin: 0 auto 2rem auto;
      font-style: italic;
      text-align: center; }
      .c-footer section .c-footer_contact a {
        color: #006483;
        text-decoration: none;
        border-bottom: 1px dotted; }
        .c-footer section .c-footer_contact a:hover {
          color: #004362;
          text-decoration: none;
          border-bottom: 1px solid #004362; }
  .c-footer .c-footer_submenu {
    background-color: #500000;
    padding: 0.75rem 0; }
    .c-footer .c-footer_submenu ul {
      display: flex;
      justify-content: center;
      text-align: center;
      text-transform: uppercase; }
      @media (max-width: 768px) {
        .c-footer .c-footer_submenu ul {
          flex-direction: column; } }
      .c-footer .c-footer_submenu ul li {
        list-style: none;
        margin: 0.5rem 1rem; }
        @media (min-width: 768px) {
          .c-footer .c-footer_submenu ul li {
            margin: 1rem; } }
        .c-footer .c-footer_submenu ul li a {
          font-size: 1rem;
          color: #ffffff;
          text-decoration: none;
          border-bottom: 1px dotted; }
          .c-footer .c-footer_submenu ul li a:hover {
            color: #939393;
            text-decoration: none;
            border-bottom: 1px solid; }
