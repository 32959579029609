//  Table of Contents:
//
//  1. Tungsten & Open Sans
//  2. Color Variables
//  3. Background Colors, incriments of 100
//  4. Font Weights, incriments of 100
//  5. Tungsten Font Weights, incriments of 100
//  6. Open Sans Font Weights, incriments of 100
//  7. Base Font Sizes
//  8. Font Sizes, xs-xl
//  9. Font Colors for light bg, incriments of 100
// 10. Font Colors for dark bg, incriments of 100
// 11. Text Align
// 12. Text Transform
// 13. Borders
// 14. Padding, xs-xl
// 15. Margins, sm-xl
// 16. Vertical Alignments
// 17. Widths, incriments of 10

@import url('https://cloud.typography.com/6280314/6684992/css/fonts.css');
/* -- Open Sans -- */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

$tungsten: 'Tungsten A', 'Tungsten B', sans-serif;
$open-sans: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* -- Color Variables -- */
$maroon: #500000;
$maroon-100: #732f2f;
$maroon-200: #500000;
$maroon-300: #3C0000;

$red: #e4002b;
$white: #ffffff;
$green: #3ed488;
$black: #000000;

$yellow: #fce300;
$yellow-100: #fce300;
$yellow-200: #f0c600;

$gray-100: #f6f8f7;
$gray-200: #eef0f0;
$gray-300: #d1d5d4;
$gray-400: #a7abaa;
$gray-500: #707373;
$gray-600: #3e3e3e;
$gray-700: #332c2c;
$gray-800: #2b2021;
$gray-900: #241618;
$gray-1000: #0a0a0a;

$grey-100: #006D8F;
$grey-200: #eef0f0;
$grey-300: #d1d5d4;
$grey-400: #a7abaa;
$grey-500: #707373;
$grey-600: #3e3e3e;
$grey-700: #332c2c;
$grey-800: #2b2021;
$grey-900: #241618;
$grey-1000: #0a0a0a;

$blue-100: #006483;
$blue-200: #004362;

$tan-100: #f5efe6;
$tan-200: #e7ded0;
$tan-300: #d6d3c4;

/* --Background Colors -- */
.u-bg-maroon {background-color:$maroon !important;}
.u-bg-maroon-100 {background-color:$maroon-100 !important;}
.u-bg-maroon-200 {background-color:$maroon-200 !important;}
.u-bg-maroon-300 {background-color:$maroon-300 !important;}

.u-bg-red {background-color:$red !important;}
.u-bg-yellow {background-color:$yellow !important;}
.u-bg-green {background-color:$green !important;}

.u-bg-gray-100 {background-color:$gray-100 !important;}
.u-bg-gray-200 {background-color:$gray-200 !important;}
.u-bg-gray-300 {background-color:$gray-300 !important;}
.u-bg-gray-400 {background-color:$gray-400 !important;}
.u-bg-gray-500 {background-color:$gray-500 !important;}
.u-bg-gray-600 {background-color:$gray-600 !important;}
.u-bg-gray-700 {background-color:$gray-700 !important;}
.u-bg-gray-800 {background-color:$gray-800 !important;}
.u-bg-gray-900 {background-color:$gray-900 !important;}

.u-bg-black {background-color:$black !important;}

.u-bg-blue-100 {background-color:$blue-100 !important;}
.u-bg-blue-200 {background-color:$blue-200 !important;}

.u-bg-tan-100 {background-color:$tan-100 !important;}
.u-bg-tan-200 {background-color:$tan-200 !important;}
.u-bg-tan-300 {background-color:$tan-300 !important;}

/* -- Font Weights -- */
.u-font-weight-300 {font-weight: 300 !important;}
.u-font-weight-400 {font-weight: 400 !important;}
.u-font-weight-600 {font-weight: 600 !important;}
.u-font-weight-700 {font-weight: 700 !important;}
.u-font-weight-800 {font-weight: 800 !important;}

/* --  Font Weights, Tungsten -- */
.u-tungsten {font-family: 'Tungsten A', 'Tungsten B';font-weight: 400;letter-spacing: 2px !important;}
.u-tungsten-400 {font-family: 'Tungsten A', 'Tungsten B';font-weight: 400;letter-spacing: 2px !important;}
.u-tungsten-600 {font-family: 'Tungsten A', 'Tungsten B';font-weight: 600;letter-spacing: 2px !important;}
.u-tungsten-700 {font-family: 'Tungsten A', 'Tungsten B';font-weight: 700;letter-spacing: 2px !important;}

/* -- Font Weights, Tungsten Narrow -- */
.u-tungsten-narrow {font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';font-weight: 400;letter-spacing: 2px !important;}
.u-tungsten-narrow-400 {font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';font-weight: 400;letter-spacing: 2px !important;}
.u-tungsten-narrow-600 {font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';font-weight: 600;letter-spacing: 2px !important;}
.u-tungsten-narrow-700 {font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';font-weight: 700;letter-spacing: 2px !important;}

/* -- Font Weights, Open Sans -- */
.u-open-sans {font-family: 'Open Sans', sans-serif;font-weight: 400 !important;}
.u-open-sans-300 {font-family: 'Open Sans', sans-serif;font-weight: 300 !important;}
.u-open-sans-400 {font-family: 'Open Sans', sans-serif;font-weight: 400 !important;}
.u-open-sans-600 {font-family: 'Open Sans', sans-serif;font-weight: 600 !important;}
.u-open-sans-700 {font-family: 'Open Sans', sans-serif;font-weight: 700 !important;}
.u-open-sans-800 {font-family: 'Open Sans', sans-serif;font-weight: 800 !important;}

/* -- Base Font Sizes -- */


/* -- Font Sizes, s-xl -- */
.u-font-size-xs {font-size: .85em !important;}
.u-font-size-sm {font-size: 1em !important;}
.u-font-size-md {font-size: 1.25em !important;}
.u-font-size-lg {font-size: 1.5em !important;}
.u-font-size-xl {font-size: 1.75em !important;}

/* -- Font Colors for white or light backgrounds, check contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-maroon {color: $maroon !important;}
.u-color-gray-500 {color: $gray-500 !important;}
.u-color-gray-600 {color: $gray-600 !important;}
.u-color-gray-700 {color: $gray-700 !important;}
.u-color-gray-800 {color: $gray-800 !important;}
.u-color-gray-900 {color: $gray-900 !important;}

.u-color-black {color: $black !important;}

.u-color-blue-100 {color: $blue-100 !important;}
.u-color-blue-200 {color: $blue-200 !important;}

/* -- Font Colors for dark backgrounds, check color contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-white {color: $white};
.u-color-gray-100 {color: $gray-100 !important;}
.u-color-gray-200 {color: $gray-200 !important;}
.u-color-gray-200 {color: $gray-300 !important;}

/* -- Text Align -- */
.u-text-align-l {text-align: left !important;}
.u-text-align-c {text-align: center !important;}
.u-text-align-r {text-align: right !important;}
.u-text-align-i {text-align: inherit !important;}

/* -- Text Transform -- */
.u-text-transform-u {text-transform: uppercase !important;}
.u-text-transform-l {text-transform: uppercase !important;}

/* -- Letter Spacing -- */
.u-letter-spacing-1 {letter-spacing: 1px !important;}
.u-letter-spacing-2 {letter-spacing: 2px !important;}
.u-letter-spacing-3 {letter-spacing: 3px !important;}

/* -- Borders -- */
.u-border {border: 1px solid !important;}
.u-border-t {border-top: 1px solid !important;}
.u-border-r {border-right: 1px solid !important;}
.u-border-b {border-bottom: 1px solid !important;}
.u-border-l {border-left: 1px solid !important;}

/* -- Padding -- */
.u-padding-sm {padding: 1rem !important;}
.u-padding-md {padding: 1.25rem !important;}
.u-padding-lg {padding: 1.5rem !important;}
.u-padding-xl {padding: 2rem !important;}

.u-padding-t-sm {padding-top: 1rem !important;}
.u-padding-t-md {padding-top: 1.25rem !important;}
.u-padding-t-lg {padding-top: 1.5rem !important;}
.u-padding-t-xl {padding-top: 2rem !important;}

.u-padding-r-sm {padding-right: 1rem !important;}
.u-padding-r-md {padding-right: 1.25rem !important;}
.u-padding-r-lg {padding-right: 1.5rem !important;}
.u-padding-r-xl {padding-right: 2rem !important;}

.u-padding-b-sm {padding-bottom: 1rem !important;}
.u-padding-b-md {padding-bottom: 1.25rem !important;}
.u-padding-b-lg {padding-bottom: 1.5rem !important;}
.u-padding-b-xl {padding-bottom: 2rem !important;}

.u-padding-l-sm {padding-left: 1rem !important;}
.u-padding-l-md {padding-left: 1.25rem !important;}
.u-padding-l-lg {padding-left: 1.5rem !important;}
.u-padding-l-xl {padding-left: 2rem !important;}

/* -- Margins -- */
.u-margin-center {margin-left: auto !important;margin-right: auto !important;}

.u-margin-sm {margin: 1rem !important;}
.u-margin-md {margin: 1.25rem !important;}
.u-margin-lg {margin: 1.5rem !important;}
.u-margin-xl {margin: 2rem !important;}

.u-margin-t-sm {margin-top: 1rem !important;}
.u-margin-t-md {margin-top: 1.25rem !important;}
.u-margin-t-lg {margin-top: 1.5rem !important;}
.u-margin-t-xl {margin-top: 2rem !important;}

.u-margin-r-sm {margin-right: 1rem !important;}
.u-margin-r-md {margin-right: 1.25rem !important;}
.u-margin-r-lg {margin-right: 1.5rem !important;}
.u-margin-r-xl {margin-right: 2rem !important;}

.u-margin-b-sm {margin-bottom: 1rem !important;}
.u-margin-b-md {margin-bottom: 1.25rem !important;}
.u-margin-b-lg {margin-bottom: 1.5rem !important;}
.u-margin-b-xl {margin-bottom: 2rem !important;}

.u-margin-l-sm {margin-left: 1rem !important;}
.u-margin-l-md {margin-left: 1.25rem !important;}
.u-margin-l-lg {margin-left: 1.5rem !important;}
.u-margin-l-xl {margin-left: 2rem !important;}

/* -- Vertical Alignments -- */
.u-vertical-align-t {vertical-align: top !important;}
.u-vertical-align-m {vertical-align: middle !important;}
.u-vertical-align-b {vertical-align: bottom !important;}

.u-vertical-align-text-t {vertical-align: text-top !important;}
.u-vertical-align-text-b {vertical-align: text-bottom !important;}

/* -- Widths -- */
.u-width-10 {width: 10% !important;}
.u-width-20 {width: 20% !important;}
.u-width-30 {width: 30% !important;}
.u-width-40 {width: 40% !important;}
.u-width-50 {width: 50% !important;}
.u-width-60 {width: 60% !important;}
.u-width-70 {width: 70% !important;}
.u-width-80 {width: 80% !important;}
.u-width-90 {width: 90% !important;}
.u-width-100 {width: 100% !important;}

.show-for-sr {display: none;}
